@import "./variables.scss";

@font-face {
  font-family: "Get Schwifty";
  src: url("../assets/fonts/get_schwifty.ttf");
  font-weight: 400;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  user-select: none;

  body {
    background: $gradient;
    font-family: $font;
    min-height: 100vh;
    scroll-behavior: smooth;
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: 100vh;

    a {
      text-decoration: none;
      text-decoration-skip-ink: auto;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    min-height: 100vh;
    overflow-y: auto;
    padding: 2rem;
  }
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($main-color, 0.8);
}

::-webkit-scrollbar-thumb:hover {
  background-color: $main-color;
}

:focus-visible {
  outline: 2px dashed $main-color;
  outline-offset: 3px;
}

:focus:not(:focus-visible) {
  outline: none;
  outline-offset: 0px;
}

:focus {
  outline: 2px dashed $main-color;
  outline-offset: 3px;
}

.hide {
  animation: fadeOut 0.3s ease-in-out forwards;
  opacity: 0 !important;
  visibility: hidden !important;
}

.show {
  animation: fadeIn 0.3s ease-in-out forwards;
  opacity: 1 !important;
  visibility: visible !important;
}

.skeleton {
  animation: bg-skeleton 1s linear infinite alternate;
  background-color: rgba($main-color, 0.05);
}

@keyframes bg-skeleton {
  0% {
    background-color: rgba($main-color, 0.1);
  }

  100% {
    background-color: rgba($main-color, 0.2);
  }
}