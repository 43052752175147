@use "styles/variables.scss";

.button {
  background-color: variables.$main-color;
  border: none;
  border-radius: 0.5rem;
  color: variables.$main-text-color;
  cursor: pointer;
  font: variables.$fz-md variables.$font;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease-in-out;
  user-select: none;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not([disabled]):hover, &:not([disabled]):focus {
    filter: brightness(1.1);
    transform: scale(1.1);
  }

  &:not([disabled]):active {
    filter: brightness(0.9);
    transform: scale(0.9);
    transition: all 0.1s ease-in-out;
  }

  &:not([disabled]):focus {
    outline: none;
  }
}