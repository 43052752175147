@use "styles/variables.scss";

.favorites {
  width: 100%;
  max-width: variables.$max-width;
  height: fit-content;
  display: block;
  margin: 0 auto;

  &__container {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, 10rem);
    gap: 0.5rem;
    padding: 1rem;
    overflow: auto;

    &_active {
      cursor: grabbing;

      * {
        cursor: grabbing;
      }
    }

    &::-webkit-scrollbar {
      height: 1rem;
    }
  }

  &__title {
    color: variables.$main-color;
    font-size: variables.$fz-lg;
    font-weight: bold;
    letter-spacing: 0.1rem;
  }

  &__empty {
    color: variables.$secondary-text-color;
    font-size: variables.$fz-lg;
    text-align: center;
  }
}