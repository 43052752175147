$main-color: #86ff4b;
$secondary-color: #68c8ff;
$main-text-color: #000000;
$secondary-text-color: #ffffff;
$gradient: #000020 fixed linear-gradient(rgb(0, 0, 20), rgb(0, 0, 40) 70%, rgb(0, 0, 80) 100%);
$bg-color: #000000;
$error: #ff0000;
$font: "Get Schwifty", "Helvetica Neue", sans-serif;
$max-width: 125rem;
$fz-xxs: 1.2rem;
$fz-xs: 1.4rem;
$fz-sm: 1.6rem;
$fz-md: 1.8rem;
$fz-lg: 2rem;