.hero {
  aspect-ratio: 800 / 251;
  animation: logo__animation 3s ease-in-out infinite;
  display: block;
  height: auto;
  margin: 2rem auto;
  max-width: 80rem;
  width: 100%;
  will-change: transform;
}

@keyframes logo__animation {
  0% {
      transform: translateY(0.5rem);
  }

  50% {
      transform: translateY(-0.5rem);
  }

  100% {
      transform: translateY(0.5rem);
  }
}